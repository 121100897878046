<!-- 
	使用方式请参考 @/views/shareAndDispatch/workManager/order/shareSetting/index.vue
	各项未设有间距，如需间距，请自行使用样式设置
 -->


<template>
	<div class="list-item">
		<div class="row-1">
			<div class="title">{{ itemData[config.titleKey] }}</div>
			<div class="title-right"><slot name="titleRight"></slot></div>
		</div>

		<div class="row-2">
			<p class="row-item" v-for="(item, index) in config.layout1" :key="index">
				<span class="label">{{ item.label }}</span>
				<span class="value">{{ $isValue(itemData[item.key]) ? itemData[item.key] : '-' }}</span>
			</p>
		</div>

		<div class="row-3">
			<p class="row-item" v-for="(item, index) in config.layout2" :key="index">
				<span class="label">{{ item.label }}</span>
				<span class="value">{{ itemData[item.key] || '-' }}</span>
			</p>
		</div>

		<div class="row-4" v-if="this.$slots.footer"><slot name="footer"></slot></div>
	</div>
</template>

<script>
export default {
	name: 'listItem1',
	components: {},
	props: {
		itemData: {
			type: Object,
			default: () => {
				return {};
			}
		},
		config: {
			type: Object,
			default: () => {
				return {
					titleKey: '',
					layout1: [],
					layout2: []
				};
			}
		}
	},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.list-item {
	padding: 10px 12px;
	background: linear-gradient(180deg, #d1deff 0%, #ffffff 100%);
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	.row-1 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		line-height: 24px;
		font-weight: 700;
		.title {
			font-size: 16px;
			color: #333333;
			width: calc(100% - 90px);
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.title-right {
			font-size: 14px;
			color: #333;
		}
	}
	.row-2,
	.row-3 {
		line-height: 24px;
		.row-item {
			font-size: 12px;
		}
	}
	.row-2 {
		display: flex;
		align-items: center;
		.row-item {
			&:first-child {
				width: 40%;
			}
			&:last-child {
				width: 60%;
			}
			.label {
				color: #666;
			}
			.value {
				color: #3e73fb;
				margin-left: 12px;
			}
		}
	}
	.row-3 {
		.row-item {
			display: flex;
			justify-content: space-between;
			.label {
				color: #999;
			}
			.value {
				color: #333;
			}
		}
	}

	.row-4 {
		margin-top: 8px;
	}
}
</style>
