<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="main-wrap">
			<div class="card-list"><listItem1 v-for="(item, index) in tableData" :key="index" :itemData="item" :config="listItemConfig">
<!--				<template slot="titleRight">-->
<!--					<van-rate color="#ffd21e"-->
<!--                    void-icon="star"-->
<!--                    void-color="#eee" v-model="item.rate || 0" :size="12" />-->
<!--				</template>-->
			</listItem1></div>

			<KcEmpty v-if="!tableData.length"></KcEmpty>
		</div>
	</van-pull-refresh>
</template>

<script>
import listItem1 from '@components/listItem/listItem1';
import { getDate } from '@utils/utils.js';
export default {
	name: 'shareSetting',
	components: { listItem1 },
	inject: ['shareOrderBaseData'],
	props: {
		orderStatus: {
			type: [String, Number],
			default: ''
		},
		layout1: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			isRefresh: false,

			listItemConfig: {
				titleKey: 'deviceName',
				layout1: [],
				layout2: [
					{
						label: this.$VUEAPPDEVICECODE(),
						key: 'code'
					},
					{
						label: '卡片号',
						key: 'label'
					},
					{
						label: '资产分类',
						key: 'categoryName'
					},
					{
						label: '品牌',
						key: 'brandName'
					},
					{
						label: '型号',
						key: 'modelName'
					},
					{
						label: '科室',
						key: 'deviceTenant'
					},
				]
			},
			totalCount: 0,
			tableData: []
		};
	},
	created() {
		this.listItemConfig.layout1 = this.layout1;
		this.getList();
	},
	mounted() {
		this.$bus.on('shareOrderSearch', this.getList);
	},
	beforeDestroy() {
		this.$bus.off('shareOrderSearch', this.getList);
	},
	methods: {
		onRefresh() {
			this.isRefresh = true;
			this.getList(() => {
				setTimeout(() => {
					this.isRefresh = false;
				}, 300);
			});
		},
		getList(callbak) {
			let userInfo = this.$store.getters.userInfo;
      let post = {
        query: this.shareOrderBaseData.query,
        page: 1,
        pageSize: 100,
        tenantId: this.shareOrderBaseData.tenantId,
        categoryIds: this.shareOrderBaseData.categoryId,
        isTenantIdChild: 1,
        timeType: 1,
        orderStatus: this.orderStatus,
      }
      if (this.$route.name != 'deviceView-share-shareOrder-shareOrder6') {
        post['startTime'] = getDate() + ' 00:00:00';
        post['endTime'] = getDate() + ' 23:59:59';
      }
			this.$api.deviceOrg
				.shareOrderListByStatus(post)
				.then(res => {
					res.data.map(item => {
						item.orderTimeText = this.$filters.formatDateTime(item.orderTime);
						item.startTimeText = this.$filters.formatDateTime(item.startTime);
						item.approvalTimeText = this.$filters.formatDateTime(item.approvalTime);
					});
					this.tableData = res.data;
					if (typeof callbak == 'function') callbak();
				})
				.catch(e => {
					if (typeof callbak == 'function') callbak();
				});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';
.row-footer {
	justify-content: flex-end;
}
</style>
